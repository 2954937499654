import React, { useRef, useEffect, useState } from "react";
import { Container, GridTitle } from "../../components/Basic";
import { Link } from "react-router-dom";
import "./index.css";
import gsap from "gsap";
import { Transition } from "../../components/Transition";

const FAQ = () => {
  const faq = gsap.timeline();
  useEffect(() => {});

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <>
      <Transition
        style={{
          position: "fixed",
          width: "0%",
          height: "100vh",
          background: "#01bf71",
          display: "flex",
          right: 0,
          zIndex: 999999999999999,
          animation: "overlay 1s ease",
        }}
      />
      <div className="lol3">
        <div className="acenter">
          <GridTitle to="/">Faqs</GridTitle>
          <main className="atestimonial-grid">
            {data.map((item, i) => (
              <article className="atestimonial agrid-col-span-2 aflow abg-primary-400 aquote atext-neutral-100">
                <div onClick={() => toggle(i)}>
                  <div className="acol">
                    <h2
                      style={{
                        color:
                          selected === i ? "#01bf71" : "rgb(191, 191, 191)",
                        fontSize: "2rem",
                      }}
                      className="number"
                    >
                      {item.question}
                    </h2>
                  </div>
                </div>
                <div className={selected === i ? "acontent show" : "acontent"}>
                  <p>{item.answer}</p>
                </div>
              </article>
            ))}
          </main>
        </div>
      </div>
    </>
  );
};

export default FAQ;

const data = [
  {
    question: "What is the Sacred Yak Union?",
    answer:
      "The Sacred Yak Union is an algorithmically-generated collection of 4,000 heroical yak NFTs. This collection includes 6,848 OG yaks and 10 Legendary yaks. Note: 68 NFTs will be kept for giveaways and promotions.",
  },
  {
    question: "Where does the Sacred Yak Union stored?",
    answer:
      "The Sacred Yak Union NFTs are securely stored on Arweave, a decentralized data storage. Yaks will stay there forever to provide continued protection for all the metaverses.",
  },
  {
    question: "When is the launch?",
    answer: "??????????",
  },
  {
    question: "Will there be reward tokens?",
    answer:
      "Yes, our team wants what is best for our Sacred Yak Union members, so after discussing with the community, we will make the decision of how and when together.",
  },
  {
    question: "How much for minting?",
    answer: "0.08 ETH",
  },
  { question: "Which wallet will you support?", answer: "Metamask" },
  {
    question: "Which marketplaces will Sacred Yak Union list on?",
    answer:
      "Ethereum NFT Projects will be automatically availabe on the secondary market which is OpenSea",
  },
  {
    question: "How much are the royalties?",
    answer:
      "The total amount of royalties is 10%. Half of the royalties will go to our community vault for future donation. For further information about The Sacred Yak Union, please join our discord channel to meet our engaging and friendly community members.",
  },
];
