import React from "react";
import styled from "styled-components";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SideBtnWrap,
  SidebarWrapper,
  SidebarLink,
  SidebarMenu,
  SidebarRoute,
} from "./SidebarElements";

import pdf1 from "../../components/pdf/domestic_yaks.pdf";
import pdf2 from "../../components/pdf/wild_yaks.pdf";
import { FaDiscord, FaTwitter } from "react-icons/fa";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <SidebarWrapper>
          <Icon onClick={toggle}>
            <CloseIcon />
          </Icon>

          <SidebarMenu>
            <SidebarLink to="/storyline">STORYLINE</SidebarLink>
          </SidebarMenu>
          <SidebarMenu>
            <SidebarLink to="/faq">FAQ</SidebarLink>
          </SidebarMenu>
          <SidebarMenu>
            <SidebarLink to="/roadmap">ROADMAP</SidebarLink>
          </SidebarMenu>
          <SidebarMenu>
            <SidebarLink to="/team">TEAM</SidebarLink>
          </SidebarMenu>
          <SidebarMenu>
            <SidebarLink to={pdf1}>DOMESTIC YAKS</SidebarLink>
          </SidebarMenu>
          <SidebarMenu>
            <SidebarLink to={pdf2}>WILD YAKS</SidebarLink>
          </SidebarMenu>

          {/* <SidebarMenu>
            <PDF href={pdf1}>DOMESTIC YAKS</PDF>
          </SidebarMenu>
          <SidebarMenu>
            <PDF href={pdf2}>WILD YAKS</PDF>
          </SidebarMenu> */}
          <br />
          <SideBtnWrap>
            <SidebarRoute to="/mint">MINT</SidebarRoute>
          </SideBtnWrap>
          <div style={{ color: "#000", padding: 20, textDecoration: "none" }}>
            <a style={{ color: "#000", textDecoration: "none" }} href="">
              <FaDiscord />
            </a>
            <a style={{ color: "#000", textDecoration: "none" }} href="">
              <FaTwitter />
            </a>
          </div>
        </SidebarWrapper>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;

const PDF = styled.a`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 16px 64px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #01bf71;
    color: #fff;
  }
`;
