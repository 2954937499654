import React, { useState } from "react";
import { HeroBg} from "./HeroElements";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "./test.css";
import Welcome from "../Welcome";

const HeroSection = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="lol">
      <HeroBg>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <Welcome/>
      </HeroBg>
    </div>
  );
};

export default HeroSection;
