import React, { useEffect, useState, useRef } from "react";
import { Container, GridTitle } from "../../components/Basic";
import { Link } from "react-router-dom";
import "./index.css";
import gsap, { Power4 } from "gsap";
import styled from "styled-components";
import member1 from "../../components/images/zzyak.png";
import member2 from "../../components/images/Tashi.png";
import member3 from "../../components/images/clair.png";
import member4 from "../../components/images/jie.png";
import { FiTwitter, FiInstagram, FiFacebook } from "react-icons/fi";
import { Transition } from "../../components/Transition";

const Team = () => {
  const [selected, setSelected] = useState(null);
  const [selected2, setSelected2] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  const toggle2 = (i) => {
    if (selected2 === i) {
      return setSelected(null);
    }
    setSelected2(i);
  };

  const team = gsap.timeline();
  const mem1 = useRef(null);
  const mem2 = useRef(null);
  const mem3 = useRef(null);
  const mem4 = useRef(null);
  useEffect(() => {
    team.from(mem1.current, {
      delay: 1,
      duration: 1,
      x: -10,
      opacity: 0,
      ease: Power4.easeInOut,
    });
    team.from(mem2.current, {
      duration: 1,
      x: -10,
      opacity: 0,
      ease: Power4.easeInOut,
    });
    team.from(mem3.current, {
      duration: 1,
      x: -10,
      opacity: 0,
      ease: Power4.easeInOut,
    });
    team.from(mem4.current, {
      duration: 1,
      x: -10,
      opacity: 0,
      ease: Power4.easeInOut,
    });
  });
  return (
    <>
      <Transition
        style={{
          position: "fixed",
          width: "0%",
          height: "100vh",
          background: "#01bf71",
          display: "flex",
          right: 0,
          zIndex: 999999999999999,
          animation: "overlay 1s ease",
        }}
      />
      <div className="lol5">
        <div className="ccenter">
          <GridTitle to="/">Team</GridTitle>
          <main className="ctestimonial-grid">
            <article
              ref={mem1}
              className="ctestimonial cgrid-col-span-2 cflow cbg-primary-400 cquote ctext-neutral-100"
            >
              <img src={member1} alt="" className="profile" />
              <div>
                <h1
                  className="number"
                  style={{
                    borderBottom: "1px solid #fff",
                  }}
                >
                  Zzyak
                </h1>
                <h2 style={{ marginBottom: 10 }}>Founder</h2>
                <p>Spent ten years wandering around in Tibet Plateau.</p>
                <br />
                <div>
                  <Social href="https://facebook.com">
                    <FiFacebook />
                  </Social>
                  <Social href="https://facebook.com">
                    <FiTwitter />
                  </Social>
                  <Social href="https://facebook.com">
                    <FiInstagram />
                  </Social>
                </div>
              </div>
            </article>
            <article
              ref={mem2}
              className="ctestimonial cgrid-col-span-2 cflow cbg-primary-400 cquote ctext-neutral-100"
            >
              <img src={member2} alt="" className="profile" />
              <div>
                <h1
                  className="number"
                  style={{
                    borderBottom: "1px solid #fff",
                  }}
                >
                  Tashi
                </h1>
                <h2 style={{ marginBottom: 10 }}>Artist</h2>
                <p>
                  Former designer of a major game company. A very patient
                  gentleman, whatever your request is, he will deliver.
                </p>
                <br />
                <div>
                  <Social href="https://facebook.com">
                    <FiFacebook />
                  </Social>
                  <Social href="https://facebook.com">
                    <FiTwitter />
                  </Social>
                  <Social href="https://facebook.com">
                    <FiInstagram />
                  </Social>
                </div>
              </div>
            </article>

            <article
              ref={mem3}
              className="ctestimonial cgrid-col-span-2 cflow cbg-primary-400 cquote ctext-neutral-100"
            >
              <img src={member3} alt="" className="profile" />
              <div>
                <h1
                  className="number"
                  style={{
                    borderBottom: "1px solid #fff",
                  }}
                >
                  Clair
                </h1>
                <h2 style={{ marginBottom: 10 }}>Head of Marketing</h2>
                <p>
                  Love volunteering activities, animal lover, full of positive
                  energy.
                </p>
                <br />
                <div>
                  <Social href="https://facebook.com">
                    <FiFacebook />
                  </Social>
                  <Social href="https://facebook.com">
                    <FiTwitter />
                  </Social>
                  <Social href="https://facebook.com">
                    <FiInstagram />
                  </Social>
                </div>
              </div>
            </article>

            <article
              ref={mem4}
              className="ctestimonial cgrid-col-span-2 cflow cbg-primary-400 cquote ctext-neutral-100"
            >
              <img src={member4} alt="" className="profile" />
              <div>
                <h1
                  className="number"
                  style={{
                    borderBottom: "1px solid #fff",
                  }}
                >
                  Jie
                </h1>
                <h2 style={{ marginBottom: 10 }}>Developer</h2>
                <p>
                  A friend who is always ready to help you. A passionate
                  programmer.
                </p>
                <br />
                <div>
                  <Social href="https://facebook.com">
                    <FiFacebook />
                  </Social>
                  <Social href="https://facebook.com">
                    <FiTwitter />
                  </Social>
                  <Social href="https://facebook.com">
                    <FiInstagram />
                  </Social>
                </div>
              </div>
            </article>
          </main>
        </div>
      </div>
    </>
  );
};

export default Team;

const Social = styled.a`
  color: #000;
  text-decoration: none;
`;
