import styled from "styled-components";

export const HeroContainer = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // padding: 0 30px;
  // height: 800px;
  // position: relative;
  // z-index: 1;
  // /* Add :before styles */

  height: 100vh;
  min-width: 100%;
  background-image: url(../images/background_a.gif);
  background-color: #ccc;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

export const HeroBg = styled.div`
background-image: url(../images/background.png);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ImageBg = styled.img`
  width: 100%;
  height: 100%;
  -0-object-fit: cover;
  object-fit: cover;
`;