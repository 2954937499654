import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const HeadingTitle = styled.h1`
  font-size: 4rem;
`;

export const Container = styled.div`
  display: block;
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-items: center;
`;

export const InnerContainer = styled.div`
  display: grid;
  width: min(95%, 70rem);
  margin-inline: auto;
`;

export const Grid = styled.div`
  display: inline-block;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-items: center;
`;

export const Description = styled.p`
  font-size: 1rem;
`;

export const Image = styled.img`
  height: 200px;
  width: 200px;
`;

export const GridContainer = styled.div`
  display: inline-block;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-items: center;
`;

export const GridTitle = styled(LinkR)`
  letter-spacing: 0.1rem;
  text-decoration: none;
  font-size: 2rem;
  color: white;
  @hover {
    cursor: pointer;
  }
`;
