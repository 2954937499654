import React, { useEffect, useState } from "react";
import { Container, GridTitle } from "../../components/Basic";
import { Link } from "react-router-dom";
import { Transition } from "../../components/Transition";
import "./index.css";
import gsap from "gsap";

const Roadmap = () => {
  const road = gsap.timeline();
  useEffect(() => {});

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <>
      <Transition
        style={{
          position: "fixed",
          width: "0%",
          height: "100vh",
          background: "#01bf71",
          display: "flex",
          right: 0,
          zIndex: 999999999999999,
          animation: "overlay 1s ease",
        }}
      />
      <div className="lol4">
        <div className="bcenter">
          <GridTitle to="/">Roadmap</GridTitle>
          <div className="center">
            <main className="testimonial-grid">
              {data.map((item, i) => (
                <article className="testimonial grid-col-span-2 flow bg-primary-400 quote text-neutral-100">
                  <div onClick={() => toggle(i)}>
                    <div className="col">
                      <h2 className="name">{item.question}</h2>
                      <h1 className="number">{item.number}</h1>
                    </div>
                  </div>
                  <div className="content show">
                    <p>{item.answer}</p>
                  </div>
                </article>
              ))}
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;

const data = [
  {
    number: `${0 + "1"}`,
    question: "Phase",
    answer:
      "During Phase 1, the team will pre-mint 1,000 yaks for early supporters and mint the remaining tokens for everyone else.",
  },
  {
    number: `${0 + "2"}`,
    question: "Phase",
    answer:
      "Next, the yaks will be ready for secondary market listing. We will also auction off 10 Legendary yaks.",
  },
  {
    number: `${0 + "3"}`,
    question: "Phase",
    answer:
      "Donation and charity are a major priority for The Sacred Yak Union. We believe that helping those in need is essential to the heart and soul of the Union. We will create a community vault that is funded by 50% of all the secondary market sales royalties and 50% of the Legendary auction. The vault will be used to provide donations and support to others within the group on a monthly basis. The community will decide how the money will be used with none going to the organization itself. In addition, the development team has committed to adding their own money into the vault. But first, our team will work to free some of the yaks being brutally killed by butchers on the Tibetan Plateau. We’ll accomplish this by incentivizing local nomads to not sell the yaks and provide care for them for the rest of their natural lives. The Sacred Yak Union may not be a charity and we’re not trying to be saints. But, we are a group of people who are passionate about pitching in and helping defend others from harm in life. We’re proud to be members of The Sacred Yak Union!",
  },
  {
    number: `${0 + "4"}`,
    question: "Phase",
    answer:
      "In this phase, we will prepare the yaks’ pixel bodies to traverse around the metaverse. Our team will create 3D Voxelized versions of each yak so NFT owners can use them as their personal avatars across multiple metaverse platforms. While each yak and their story is different, they are all great heroes full of confidence and love. We hope you will spread their noble spirit to wherever your travels take you within the metaverse.",
  },
  {
    number: `${0 + "5"}`,
    question: "Phase",
    answer:
      "Following the 3D rendering of all yaks, we will focus on developing P2E games. The yaks need constant training to help them protect other living beings and face tough challenges. The games will be designed to give members the ability to arm their yaks, gamble their tokens, and put up a good fight. Will a failed yak get depressed or hold a grudge? Certainly not! The brave yaks will simply focus on their mistakes, continue training, and prepare for the next battle. (Note: content and methods may change with progress).",
  },
  {
    number: `${0 + "6"}`,
    question: "Phase",
    answer:
      "Finally, we’ll roll out real-world merchandise, so members can proudly show off their Sacred Yak Union swag!",
  },
];
