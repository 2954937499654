import React, { useEffect, useRef } from "react";
import { Container, GridTitle } from "../../components/Basic";
import { Link } from "react-router-dom";
import "./index.css";
import gsap, { Power4 } from "gsap";
import { EasePack } from "gsap/all";
import { Transition } from "../../components/Transition";

const Storyline = () => {
  const storyline = gsap.timeline();
  const overlay = useRef(null);
  const content = useRef(null);
  const number = useRef(null);
  const content1 = useRef(null);
  const number1 = useRef(null);
  const content2 = useRef(null);
  const number2 = useRef(null);
  const content3 = useRef(null);
  const number3 = useRef(null);
  useEffect(() => {
    storyline.from(overlay.current, {
      duration: 0.5,
      x: -10,
      opacity: 0,
    });
    storyline.from(number.current, {
      duration: 0.5,
      x: -10,
      opacity: 0,
    });
    storyline.from(content.current, {
      duration: 0.5,
      x: -10,
      opacity: 0,
    });

    storyline.from(number1.current, {
      duration: 0.5,
      x: -10,
      opacity: 0,
    });
    storyline.from(content1.current, {
      x: -10,
      opacity: 0,
    });

    storyline.from(number2.current, {
      duration: 0.5,
      x: -10,
      opacity: 0,
    });
    storyline.from(content2.current, {
      duration: 0.5,
      x: -10,
      opacity: 0,
    });

    storyline.from(number3.current, {
      duration: 0.5,
      x: -10,
      opacity: 0,
    });
    storyline.from(content3.current, {
      duration: 0.5,
      x: -10,
      opacity: 0,
    });
  });
  return (
    <>
      <Transition
        style={{
          position: "fixed",
          width: "0%",
          height: "100vh",
          background: "#01bf71",
          display: "flex",
          right: 0,
          zIndex: 999999999999999,
          animation: "overlay 1s ease",
        }}
      />
      <div className="lol2">
        <div className="bcenter">
          <GridTitle to="/">Storyline</GridTitle>
          <main className="btestimonial-grid">
            <article className="btestimonial bgrid-col-span-2 bflow bbg-primary-400 bquote btext-neutral-100">
              <h1 className="number" ref={number}>
                {0 + `1`}
              </h1>
              <div ref={content}>
                <p>
                  According to folklore, hidden in a void to the west of Mount
                  Kailash, lies a sacred realm known as the Holy Metaverse. This
                  realm, which is invisible to the naked eye, is home to a group
                  of mystical yaks that belong to a decentralized organization
                  called The Sacred Yak Union.
                </p>
              </div>
            </article>
            <article className="btestimonial bgrid-col-span-2 bflow bbg-primary-400 bquote btext-neutral-100">
              <h1 className="number" ref={number1}>
                {0 + `2`}
              </h1>
              <div ref={content1}>
                <p>
                  The Sacred Yak Union is an ancient, yet technologically
                  advanced civilization. Their technology is more advanced than
                  anything found in our modern world and was used to forge a
                  portal between our universe and theirs.
                </p>
              </div>
            </article>
            <article className="btestimonial bgrid-col-span-2 bflow bbg-primary-400 bquote btext-neutral-100">
              <h1 className="number" ref={number2}>
                {0 + `3`}
              </h1>
              <div ref={content2}>
                <p>
                  For thousands of years, this Union has selected the strongest,
                  wisest, most confident, most selfless, and most positive yaks
                  from across the Tibetan Plateau to enter the Holy Metaverse.
                  Here, the yaks are transformed into their digital form and
                  given special training to enhance their wisdom and power.
                </p>
              </div>
            </article>
            <article className="btestimonial bgrid-col-span-2 bflow bbg-primary-400 bquote btext-neutral-100">
              <h1 className="number" ref={number3}>
                {0 + `4`}
              </h1>
              <div ref={content3}>
                <p>
                  The yaks are sent to different metaverses to face trials and
                  help the inhabitants of those realms. When their adventures
                  are complete, they are able to return to the Union and vow to
                  guard the safety of each universe forever. No matter who you
                  are or where you are from, you can call upon them to come and
                  protect you from danger.
                </p>
              </div>
            </article>
          </main>
        </div>
      </div>
    </>
  );
};

export default Storyline;
