import React from "react";
import styled from "styled-components";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import { IoAppsSharp, IoLogoDiscord } from "react-icons/io5";
import {
  FiAlertCircle,
  FiBook,
  FiStar,
  FiFlag,
  FiUsers,
  FiFile,
  FiFileText,
} from "react-icons/fi";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import pdf1 from "../../components/pdf/domestic_yaks.pdf";
import pdf2 from "../../components/pdf/wild_yaks.pdf";
import logo from "../../assets/images/logoImg.png";

const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <img src={logo} alt="logo" style={{
            width: "50px",
            height: "50px",
          }}/>
          <MobileIcon onClick={toggle}>
            <IoAppsSharp />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="/storyline">{<FiBook />}</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/faq">{<FiStar />}</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/roadmap">{<FiFlag />}</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/team">{<FiUsers />}</NavLinks>
            </NavItem>
            <NavItem>
              <PDF href={pdf1}>
                <FiFile />
              </PDF>
            </NavItem>
            <NavItem>
              <PDF href={pdf2}>
                <FiFileText />
              </PDF>
            </NavItem>
          </NavMenu>
          <NavMenu>
            <NavItem>
              <NavLinks2 href="https://discord.gg/qUT4ewrwCR">
                <FaDiscord />
              </NavLinks2>
            </NavItem>
            <NavItem>
              <NavLinks2 href="https://twitter.com/SacredYakUnion">
                <FaTwitter />
              </NavLinks2>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;

const PDF = styled.a`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;

  &:hover {
    border-bottom: 3px solid #01bf71;
  }
`;

const NavLinks2 = styled.a`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;

  &:hover {
    border-bottom: 3px solid #01bf71;
  }
`;
