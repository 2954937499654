import styled from "styled-components";

export const Transition = styled.div`
  position: fixed;
  width: 0%;
  height: 100vh;
  background: #01bf71;
  display: flex;
  right: 0;
  z-index: 999999999999999;

  animation: overlay 1s ease;

  @keyframes overlay {
    0% {
      right: 0;
      width: 100%;
    }
    50% {
      right: 0;
      width: 100%;
    }
    100% {
      right: -100%;
      width: 0%;
    }
  }
`;
