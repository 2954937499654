import React, { useRef, useEffect, useState } from "react";
import "./index.css";
import {
  WelcomeContent,
  WelcomeContainer,
  WelcomeText,
  WelcomeTextContainer,
  ArrowDwn,
  TitleImg,
} from "./WelcomeElements";
import "./index.css";
import image from "../images/lali.png";
import gsap, { Power4 } from "gsap";

const Welcome = () => {
  const wel = gsap.timeline();
  const titleImg = useRef(null);
  const welText = useRef(null);
  useEffect(() => {
    wel.from(titleImg.current, {
      duration: 1,
      y: 30,
      ease: Power4.easeInOut,
      opacity: 0,
    });
    wel.from(welText.current, {
      duration: 1,
      y: 30,
      ease: Power4.easeInOut,
      opacity: 0,
    });
  });

  return (
    <>
      <WelcomeContent>
        <WelcomeContainer>
          <WelcomeTextContainer>
            <TitleImg src={image} ref={titleImg} />
            <WelcomeText ref={welText}>
              The Sacred Yak Union is a collection of 4,000 unique and randomly
              generated pixel art NFT (Non-Fungible Token) Avatars. Our brave
              yaks come with over 150 assets for your blockchain adventure
              experiences, only on Ethereum.
            </WelcomeText>
          </WelcomeTextContainer>
        </WelcomeContainer>
      </WelcomeContent>
    </>
  );
};

export default Welcome;
