import styled from "styled-components";

export const WelcomeContent = styled.div`
  height: 100vh;
  min-width: 100%;
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

export const WelcomeContainer = styled.div`
  height: -webkit-max-content;
  height: max-content;
  justify-content: center !important;
  align-items: center !important;
`;

export const WelcomeTextContainer = styled.div`
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vh;
  height: -webkit-max-content;
  height: max-content;
  @media only screen and (max-width: 1200px) {
    width: 100vh;
  }
  @media only screen and (max-width: 920px) {
    width: 60vh;
  }
  @media only screen and (max-width: 820px) {
    width: 60vh;
  }
  @media only screen and (max-width: 600px) {
    width: 50vh;
  }
  @media only screen and (max-width: 500px) {
    width: 50vh;
  }
  @media only screen and (max-width: 420px) {
    width: 42vh;
  }
  @media only screen and (max-width: 400px) {
    width: 40vh;
  }
  @media only screen and (max-width: 300px) {
    width: 30vh;
  }
  @media only screen and (max-width: 250px) {
    width: 25vh;
  }
`;

export const WelcomeText = styled.p`
  color: white;
  font-size: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  -webkit-animation: page-load2 1969ms cubic-bezier(0.04, 1.2, 1, 0.94);
  animation: page-load2 1969ms cubic-bezier(0.04, 1.2, 1, 0.94);

  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 300px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-height: 800px) {
    font-size: 1rem;
  }
  @media only screen and (max-height: 600px) {
    font-size: 0.8rem;
  }
`;

export const ArrowDwn = styled.img`
  position: relative;
  margin-top: 1rem;
  height: 2rem;
  width: 2rem;
  -webkit-animation: arrow 2.5s infinite;
  animation: arrow 2s infinite ease normal;

  @media only screen and (max-width: 400px) {
    height: 0.5rem;
    width: 0.5rem;
  }
  @media only screen and (max-width: 600px) {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const TitleImg = styled.img`
  margin-top: 10vh;
  width: 90%;

  @media only screen and (max-width: 950px) {
    width: 85%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  @media only screen and (max-width: 3500px) {
    width: 90%;
  }
`;
